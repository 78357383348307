import React from 'react'
import styled from 'styled-components';
import topSectionWave from '../images/dividers/mid-section-top.svg'
import CTAButton from './CTAButton'


const lightColor = '#FFF'
const darkColor = '#1A1A1A'


const WaveDividerBot = styled.img`
  width:100%;
  margin-bottom:-10px;
`

const BottomSectionWrapper = styled.div`
  background-color: #412A5F;
  padding: 10px 5% 20px 5%;
`;

const SectionTitle = styled.h2`
  color: ${props => props.light ? lightColor : darkColor};
  ${props => props.center && 'text-align:center;'}
  font-size:22px;
  margin:20px 0 0px 0;
  font-family:"RomanaBeckerDemi";
  font-weight:300;
`



const BottomSection = ({utm, children}) => {
  return (
    <>
      <WaveDividerBot src={topSectionWave} />
      <BottomSectionWrapper>
        <SectionTitle light center>Explore More </SectionTitle>
        <CTAButton utm={utm}></CTAButton>
        {children}
      </BottomSectionWrapper>
    </>
  )
}

export default BottomSection