import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql  } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from 'swiper/react'
import starIcon from '../images/testimonials/star.svg'
import 'swiper/css/bundle'
import SwiperCore, { Pagination } from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

const Wrapper = styled.div`
  margin-top:0;
`;

const StoryCover = styled(GatsbyImage)`
  border-radius: 5px;
  width:60px;
  height:60px;
  margin-right:15px;
 
`

const TestimonialWrapper = styled.div`
 height:200px;

`

const CustomerMeta = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`

const StoryName = styled.p`
  //color:#272727;
  color:#FFF;
  font-family: "RomanaBeckerDemi";
  font-weight: 500;
  text-decoration:none;
  font-size:1.2rem;
  line-height:2rem;
  margin:0px;
` 

const StoryMeta = styled.p`
  font-size:12px;
  margin: 0px 0px;
  text-transform:uppercase;
  font-family: "Poppins";
  color: #fbf6f6;
  font-weight:500;
`

const TestimonialText = styled.h2`
  max-width: 500px;
  //color:#272727;
  color:#FFF;
  font-weight:100;
  font-family: "RomanaBeckerRegular";
  text-align:center;
  font-size:20px;
  margin:15px 0;

`

const StoryInfoWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
`

const CustomerTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StarIcon = styled.img`
  width:20px;
  height:20px;
  margin:2px;
`

const RatingWrapper = styled.div`
  display:flex;
  justify-content: center;
`

const TestimonialSection = () => {


  const data = useStaticQuery(graphql`
  {
    first: file(relativePath: {eq: "story-covers-small/swipe-right-600.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    second: file(relativePath: {eq: "story-covers-small/architect.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    third: file(relativePath: {eq: "story-covers-small/onthebus.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
    fourth: file(relativePath: {eq: "story-covers-small/into-the-woods-600.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 130, layout: CONSTRAINED)
      }
    }
  }
`)

  const testimonials = [
    {
      "text": "Always love the artwork and narrators. Everything here is so good it makes my heart happy!",
      "story": "Swipe Right",
      "imgsrc":"first",
      "storyMeta":"Elle + Joel"
    },
    {
      "text": "Captivating, thrilling and mysterious.",
      "story": "The Architect",
      "imgsrc":"second",
      "storyMeta":"Susan + Nina"
    },
    {
      "text": "I am so grateful for this Website, I am learning so much about myself trough the help of their openness.",
      "story": "On the Bus",
      "imgsrc":"third",
      "storyMeta":"Daniel + Susan"
    },
    {
      "text": "What a thoughtful and caring story. I wish I had access to content like this earlier in my life.",
      "story": "Into the Woods",
      "imgsrc":"fourth",
      "storyMeta":"Joel + Ellie"
    }
  ]

  const params = {
    slidesPerView: 1.2,
    spaceBetween: 30,
    centeredSlides: true,
    pagination: {
      clickable: false
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
  }
  
  
  return (
    <Wrapper>
      <Swiper  {...params}> 
        {testimonials.map(({text, story, imgsrc, storyMeta},index) => (
          <SwiperSlide key={index} style={{overflow: 'visible',marginTop:"30px",marginBottom:"30px"}}>
            <TestimonialWrapper>
              <RatingWrapper> 
                <StarIcon src={starIcon} alt="staricon"/>
                <StarIcon src={starIcon} alt="staricon"/>
                <StarIcon src={starIcon} alt="staricon"/>
                <StarIcon src={starIcon} alt="staricon"/>
                <StarIcon src={starIcon} alt="staricon"/>
              </RatingWrapper>
              <CustomerTextWrapper>
                <TestimonialText center >"{text}"</TestimonialText>
              </CustomerTextWrapper>
              <StoryInfoWrapper>
             
                <StoryCover alt={"testimonial story cover"} image={ data[imgsrc.replace(/-/g,'')].childImageSharp.gatsbyImageData }/> 
            
                <CustomerMeta>
                  <StoryName>{story}</StoryName>
                  <StoryMeta>{storyMeta}</StoryMeta>
                </CustomerMeta>
              </StoryInfoWrapper>
            </TestimonialWrapper>
         </SwiperSlide>
        ))}
     </Swiper> 
    </Wrapper>
  );
}

export default TestimonialSection