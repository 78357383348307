import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql  } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'

  const params = {
    slidesPerView: 1.2,
    spaceBetween: 30, 
  }
  

const teamMembers = [
  {
    name:'Jaimee Bell',
    title:'Educator and Author',
    link:'https://www.jaimeebell.com/',
    img:'jaimee'
  },
  {
    name:'Nagy Rebeka Míra',
    title:'Illustrator & Artist',
    link:'',
    img:'nagy'
  },
  {
    name:'Lisa Welsh',
    title:'Female Wellness Podcast Host',
    link:'https://inbedwithlisa.com/',
    img:'lisa'
  },
  {
    name:'Gigi Engle',
    title:'Certified Educator & Author',
    link:'https://www.missgigiengle.com/',
    img:'gigi'
  },
]


const TeamSection = ({children}) => {
  const data = useStaticQuery(graphql`
    {
      jaimee: file(relativePath: {eq: "people/jaimee.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 200, layout: CONSTRAINED)
        }
      }
      nagy: file(relativePath: {eq: "people/nagy.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 200, layout: CONSTRAINED)
        }
      }
      lisa: file(relativePath: {eq: "people/lisa.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 200, layout: CONSTRAINED)
        }
      }
      gigi: file(relativePath: {eq: "people/gigi.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 200, layout: CONSTRAINED)
        }
      }
    }
  `)


  return (
    <Wrapper>
      {children}
      <div>
        <Swiper  {...params}> 
          {teamMembers.map((member,index) => (
            <SwiperSlide key={index} style={{overflow: 'visible',marginTop:"30px",marginBottom:"30px"}}>
            <MemberWrapper index={`${member.name}-${index}`}>
              <Avatar 
                alt="Team Member Info"
                image={data[member.img].childImageSharp.gatsbyImageData}
                src='https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/d3/d385d717ac11cddb14ddcac9c0d5bbcdb08fdd92.jpg'></Avatar>
              <InfoWrapper>
                <a target="_blank" rel="noreferrer" href={member.link}><h3>{member.name}</h3></a>
                <h4>{member.title}</h4>
              </InfoWrapper>
            </MemberWrapper>
           
            </SwiperSlide>
          ))}  
        </Swiper> 
      </div>
    </Wrapper>
  )
}

export default TeamSection


const Wrapper = styled.div`
  display: block;
  
  padding:100px 30px;
  >div{
    padding-top:50px;
  }
`;

const MemberWrapper = styled.div`
  display:flex;
  margin-bottom:20px;
`

const InfoWrapper = styled.div`
  margin-top:5px;
  max-width: 350px;
  >a{
    text-decoration:none;
    color:#1A1A1A;
    >h3{
      color:#1A1A1A;
      font-family:'RomanaBeckerDemi';
       font-weight:300;
      font-size:22px;
      margin:0; 
    }
  }
  >h4{
    color:#1A1A1A;
    font-family:'Poppins';
    font-size:14px;
    font-weight:100;
    margin:0;
    margin-top:10px;
  }
`

const Avatar = styled(GatsbyImage)`
  max-width: 120px;
  border-radius:50%;
  transform: translate3d(0,0,0) translateZ(1000px); // fixes safari transform bug
  margin-right: 20px;
  border: 5px solid #9D0A41;
`;