import React, { useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import styled from 'styled-components';
import { navigate } from 'gatsby';

const Wrapper = styled.div`
  z-index:999999;
  width:100%;
  height:50px;
  background:red;
  position: fixed;
  background-color:#FFF;
  display: flex;
  align-items:center;
  justify-content:center;
  text-decoration:none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  div{
    border-radius:25px;
    background-color:#9D0A41;
    padding: 10px 35px;
    text-transform:uppercase;
    display: flex;
    justify-content:center;
    color: #fff;
    font-family:'Poppins';
  }
`;

const ScrollCta = ({utm}) => {
  const [isVisible, setIsVisible] = useState()

  useScrollPosition(({ currPos }) => {
    setIsVisible(currPos.y < -450)
  },[])

  return (
    <>
      {isVisible &&
        <Wrapper onClick={()=> navigate("/register")}>
        <div>FREE ACCOUNT</div>
        </Wrapper>
      }
    </>
  )
}

export default ScrollCta