import React from 'react'
import styled from 'styled-components';
import botSectionWave from '../images/dividers/mid-section-bottom.svg'
import topSectionWave from '../images/dividers/mid-section-top.svg'

const ContentWrapper = styled.div`
  background-color:#412A5F;
  height:400px;
  width:100%;
  margin-top:-5px;
  display: flex;
  flex-direction:column;
  justify-content:center;
`;

const WaveDividerTop  = styled.img`
  width:100%;
  bottom:0px;
`

const WaveDividerBot = styled.img`
  width:100%;
  margin-bottom:-1px;
`

const Wrapper = styled.div`
  margin-top:100px;
`

const MidSection = ({children}) => {
  return (
    <Wrapper>
      <WaveDividerTop src={topSectionWave}/>
      <ContentWrapper>{children}</ContentWrapper>
      <WaveDividerBot src={botSectionWave}/>
    </Wrapper>
  )
}

export default MidSection