import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
//import faviconImg from '../../images/calico-favicon-32x32.png'

const SEO = ({ description, lang = "en",title }) => {

    const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `)



    const metaTitle = title ? title : site.siteMetadata.title
    const metaDescription = description? description : site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: 'ho8t0xboe2r8y3cxhb2yxxmo1mdh11',
        }
      ]}
    >
   {/*  <link rel="icon" type="image/png" href={faviconImg} sizes="16x16" /> */}
      <meta name="facebook-domain-verification" content="a9lx844zlzs4zt30gbc8h1hv6brthu" />
    </Helmet>
  )
}

export default SEO


