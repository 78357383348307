import React, {useState, useRef, useEffect, useLayoutEffect} from 'react'
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql  } from "gatsby"
import audio from '../audio/face-music.mp3'


const PhoneWrapper = styled.div`
  position: relative;
  border-radius:10px;
  height:320px;
  width:300px;
  padding: 5% 0 ;
  background:white;
  display: flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const PlayButton = styled.div`
  width: 25px;
  height: 30px;
  transform: scale(1.2)${props => props.playing && "translateX(-1px) "};
  box-sizing: border-box;
  border-color: transparent transparent transparent #FFF;
  border-width: 15px 0 15px 20px;
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;
  border-style: solid;
  margin-left:8px;
  ${props => props.playing && "border-style: double;"};
  ${props => props.playing && "border-width:0px 0 0px 20px;"};
  &:focus{
    outline:none !important
  }
`

const PlayButtonWrapper = styled.div`
  opacity:1;
  background: #9D0A41;
  border-radius:50%;
  height:60px;
  width: 60px;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:8px;
  position: absolute;
  z-index:999;
`

const CoverWrapper = styled.div`
 
  border-radius:6px;
  width: 180px;
  height: 180px;
  display:flex;
  justify-content:center;
  align-items:center;
  overflow: hidden;
`;


const Cover = styled(GatsbyImage)`
  
`;


const PreviewNow = styled.h3`
  font-family:"Poppins";
  font-size:16px;
  margin:0;
  text-transform:uppercase;
  font-weight:300;
`

const StoryTitle = styled.h3`
  font-family:"RomanaBeckerDemi";
  margin:0;
  text-align:center; 

`;

const Audioplayer = () => {
  const [ playing, setPlaying ] = useState(null)
  const audioRef = useRef(false)

  const data = useStaticQuery(graphql`
    {
      cover: file(relativePath: {eq: "face-the-music.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 180, layout: CONSTRAINED)
        }
      }
    }
  `)
   
  useLayoutEffect(() => {
    audioRef.current = new Audio(audio)
  }, [])

  useEffect(() => {
    if(audioRef.current && playing != null){
      if(playing) audioRef?.current?.play(); 
      if(!playing ) audioRef?.current?.pause();
    }
  }, [playing])
   

 

  return (
    <PhoneWrapper>
      <PreviewNow>Story Preview</PreviewNow>
      <CoverWrapper>
        <PlayButtonWrapper>
          <PlayButton playing={playing} onClick={() => setPlaying(!playing)}/>
        </PlayButtonWrapper>
        <Cover image={data.cover.childImageSharp.gatsbyImageData } alt="Face the Music Story cover"/>
      </CoverWrapper>
      <div>
      <StoryTitle>Face the Music</StoryTitle>
      </div>
     
    </PhoneWrapper>
  )
}

export default Audioplayer

