import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';
import topSectionWave from '../images/dividers/top-section-bottom-wave.svg'
import Testimonials from '../components/Testimonials'
import MidSection from '../components/MidSection'
import brandIcons from '../images/brands/brands.js'
import TeamSection from '../components/TeamSection'
import ScrollCta from '../components/ScrollCta'
import Audioplayer from '../components/AudioPlayer'
import BottomSection from '../components/BottomSection' 
import CTAButton from '../components/CTAButton'
import LogoScr from '../images/bloom-white.png'
import LogoColor from '../images/bloom-black.png'
import topSectionWave2 from '../images/dividers/mid-section-top.svg'
import Seo from '../components/Seo'

const lightColor = '#FFF'
const darkColor = '#1A1A1A'

const TopWrapper = styled.div`
  margin:0;
  padding:0;
  background-color:#412A5F;
  padding:10% 3% 200px 3%;
`;

const WaveDivider  = styled.img`
  width:100%;
  margin-top:-1px;
`

const SectionTitle = styled.h2`
  color: ${props => props.light ? lightColor : darkColor};
  ${props => props.center && 'text-align:center;'}
  font-size:29px;
  margin:0px 0 50px 0;
  font-family:"RomanaBeckerDemi";
  font-weight:300;
`


const TopTitle = styled.h1`
  color: ${props => props.light ? lightColor : darkColor};
  ${props => props.center && 'text-align:center;'}
  font-size:26px;
  margin:0px 0 30px 0;
  //@media(max-width)
  font-family:"RomanaBeckerDemi";
  font-weight:300;
  max-width:100%;

`


const Text = styled.p`
  font-family: "Poppins";
  color: ${props => props.light ? lightColor : darkColor};
  font-size:1rem;
  line-height: 1.5rem;
   ${props => props.center && 'text-align:center;'}
`

const PhoneWrapper = styled.div`
  display:flex;
  width: 100%;
  margin-top:-350px;
  justify-content:center;
  margin-bottom:100px;
`

const PageWrapper = styled.div`
  background-color:#FBF6F6;
  overflow: hidden;
`


const BrandsWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width: 100%;
  padding:0 5%;
  img{
    padding:0 5%;
    max-width: 40%;
    opacity:0.7;
  }
`

const LogoWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-bottom:30px;
  img{
    height:30px;
  }
`;

const LogoWrapperTop = styled(LogoWrapper)`
  padding-top:30px;
 img{
    height:40px;
  }
`

const WaveDividerTop  = styled.img`
  width:100%;
  bottom:0px;
  margin-bottom:-20px;
`


const Footer = styled.div`
  background-color:#412A5F;
  padding:30px;
  display:flex;
  justify-content:center;
  align-items:center;
  >a{
    margin-right:20px;
    color:#FFF;
    font-family:"poppins";
  }
`


const Index = ({location}) => {
   







  return (
    <>
    <PageWrapper>
      <Seo title="Immersive Audio Stories and Wellness Guides"/>
      <ScrollCta />
      <LogoWrapperTop> <img src={LogoColor} alt="bloomstories logo" /></LogoWrapperTop>
      <WaveDividerTop src={topSectionWave2}/>
      <TopWrapper>        
        <TopTitle center light>Time for you</TopTitle>
        <Text center light>Listen to audio stories for women and couples, ASMR, wellness guides and chat with your favorite characters using AI-powered chatbots.</Text>
        <CTAButton />
        <Text center light>Listen to <strong>1000+ audio stories</strong> and get in touch with yourself!</Text>
      </TopWrapper>
      <WaveDivider src={topSectionWave} alt="wave divider image"/>
      <PhoneWrapper><Audioplayer/></PhoneWrapper>
      <SectionTitle center>We’ve been featured in</SectionTitle>
      <BrandsWrapper>
        {brandIcons.map((img,index) => (
          <img src={img} key={img} alt={`brand-logo-${index}`}/>
        ))}
      </BrandsWrapper>
     
      <MidSection>
        <SectionTitle light center>What do our users say?</SectionTitle>
        <Testimonials />
      </MidSection>
      
      <TeamSection>
        <SectionTitle center>Meet our experts & ambassadors</SectionTitle>
      </TeamSection> 
      <BottomSection >
        <LogoWrapper> <img src={LogoScr}  alt="bloomstories logo"/></LogoWrapper>
        </BottomSection>   

    </PageWrapper>
    <Footer>
      <Link to={"/terms"}>Terms Of service</Link>
      <Link to={"/privacy-policy"}>Privacy Policy</Link>
    </Footer>
    </>
  )
}

export default Index

