import React from 'react'
import styled, { keyframes } from 'styled-components';
import { navigate } from 'gatsby';

const pulse = keyframes`
  0% {
   // transform: scale(0.98);
  
  }
  70% {
    
    //transform: scale(1);
    box-shadow: rgba(255, 255, 255, 0.2) 0 0 0 10px;
   
  }
  100% {
      //transform: scale(0.98);
     box-shadow: rgba(255, 9255, 255, 0.2) 0 0 0 0;
  }
`;


const ButtonWrapper = styled.div`
  border:none;
  display: flex;
  justify-content:center;
  padding:15px 20px;
  border-radius:35px;
  text-transform:uppercase;
  font-family:'Poppins';
  color:#1A1A1A;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  background-color:white;
  text-decoration:none;
  margin:30px 0;
  //animation: ${pulse} 1.5s infinite;
  animation: ${pulse} 2s infinite ease-in-out;
`




const CTA = () => {
  return (
    <>
      <ButtonWrapper onClick={()=> navigate("/register")}>CREATE FREE ACCOUNT </ButtonWrapper>
    </>
  ) 
}

export default CTA